module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"10","matomoUrl":"https://s.fixmycity.de","siteUrl":"https://plan-f.info","disableCookies":true,"respectDnt":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Plan F – Impulse für die kommunale Fahrradmobilität","short_name":"Plan F","start_url":"/","background_color":"white","theme_color":"#fff8e8","display":"minimal-ui","icon":"src/assets/planf_icon.png","legacy":false,"lang":"de-DE","theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ea5c1cf35858af91828a91adebc9846f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://plan-f.info","stripQueryString":true},
    },{
      plugin: require('../gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
