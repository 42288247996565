exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-artikel-index-tsx": () => import("./../../../src/pages/artikel/index.tsx" /* webpackChunkName: "component---src-pages-artikel-index-tsx" */),
  "component---src-pages-artikel-stellen-und-organisationen-fur-weitere-informationen-zur-radverkehrsforderung-fahrradmobilitat-und-fortbildung-tsx": () => import("./../../../src/pages/artikel/stellen-und-organisationen-fur-weitere-informationen-zur-radverkehrsforderung-fahrradmobilitat-und-fortbildung.tsx" /* webpackChunkName: "component---src-pages-artikel-stellen-und-organisationen-fur-weitere-informationen-zur-radverkehrsforderung-fahrradmobilitat-und-fortbildung-tsx" */),
  "component---src-pages-artikel-warum-ist-beteiligung-wichtig-tsx": () => import("./../../../src/pages/artikel/warum-ist-beteiligung-wichtig.tsx" /* webpackChunkName: "component---src-pages-artikel-warum-ist-beteiligung-wichtig-tsx" */),
  "component---src-pages-artikel-warum-soll-ich-den-radverkehr-in-meiner-kommune-fordern-was-sind-die-positiven-effekte-tsx": () => import("./../../../src/pages/artikel/warum-soll-ich-den-radverkehr-in-meiner-kommune-fordern-was-sind-die-positiven-effekte.tsx" /* webpackChunkName: "component---src-pages-artikel-warum-soll-ich-den-radverkehr-in-meiner-kommune-fordern-was-sind-die-positiven-effekte-tsx" */),
  "component---src-pages-artikel-welche-motive-fur-die-nutzung-bzw-nicht-nutzung-des-fahrrads-gibt-es-tsx": () => import("./../../../src/pages/artikel/welche-motive-fur-die-nutzung-bzw-nicht-nutzung-des-fahrrads-gibt-es.tsx" /* webpackChunkName: "component---src-pages-artikel-welche-motive-fur-die-nutzung-bzw-nicht-nutzung-des-fahrrads-gibt-es-tsx" */),
  "component---src-pages-artikel-welches-budget-sollte-ich-fur-die-forderung-der-fahrradmobilitat-ansetzen-tsx": () => import("./../../../src/pages/artikel/welches-budget-sollte-ich-fur-die-forderung-der-fahrradmobilitat-ansetzen.tsx" /* webpackChunkName: "component---src-pages-artikel-welches-budget-sollte-ich-fur-die-forderung-der-fahrradmobilitat-ansetzen-tsx" */),
  "component---src-pages-artikel-wie-werde-ich-eine-fahrradfreundliche-kommune-tsx": () => import("./../../../src/pages/artikel/wie-werde-ich-eine-fahrradfreundliche-kommune.tsx" /* webpackChunkName: "component---src-pages-artikel-wie-werde-ich-eine-fahrradfreundliche-kommune-tsx" */),
  "component---src-pages-award-plan-f-award-2022-tsx": () => import("./../../../src/pages/award/plan-f-award-2022.tsx" /* webpackChunkName: "component---src-pages-award-plan-f-award-2022-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-plan-f-check-[result]-tsx": () => import("./../../../src/pages/plan-f-check/[result].tsx" /* webpackChunkName: "component---src-pages-plan-f-check-[result]-tsx" */),
  "component---src-pages-plan-f-check-fragebogen-tsx": () => import("./../../../src/pages/plan-f-check/fragebogen.tsx" /* webpackChunkName: "component---src-pages-plan-f-check-fragebogen-tsx" */),
  "component---src-pages-plan-f-check-index-tsx": () => import("./../../../src/pages/plan-f-check/index.tsx" /* webpackChunkName: "component---src-pages-plan-f-check-index-tsx" */),
  "component---src-pages-ueber-tsx": () => import("./../../../src/pages/ueber.tsx" /* webpackChunkName: "component---src-pages-ueber-tsx" */),
  "component---src-pages-weitere-angebote-audits-tsx": () => import("./../../../src/pages/weitere-angebote/audits.tsx" /* webpackChunkName: "component---src-pages-weitere-angebote-audits-tsx" */),
  "component---src-pages-weitere-angebote-e-learning-tsx": () => import("./../../../src/pages/weitere-angebote/e-learning.tsx" /* webpackChunkName: "component---src-pages-weitere-angebote-e-learning-tsx" */),
  "component---src-pages-weitere-angebote-handbuch-tsx": () => import("./../../../src/pages/weitere-angebote/handbuch.tsx" /* webpackChunkName: "component---src-pages-weitere-angebote-handbuch-tsx" */),
  "component---src-pages-weitere-angebote-index-tsx": () => import("./../../../src/pages/weitere-angebote/index.tsx" /* webpackChunkName: "component---src-pages-weitere-angebote-index-tsx" */),
  "component---src-pages-wissensspeicher-index-tsx": () => import("./../../../src/pages/wissensspeicher/index.tsx" /* webpackChunkName: "component---src-pages-wissensspeicher-index-tsx" */),
  "component---src-pages-wissensspeicher-strapi-example-measure-topic-name-strapi-example-measure-slug-strapi-example-slug-tsx": () => import("./../../../src/pages/wissensspeicher/{strapiExample.measure__topic__name}/{strapiExample.measure__slug}/{strapiExample.slug}.tsx" /* webpackChunkName: "component---src-pages-wissensspeicher-strapi-example-measure-topic-name-strapi-example-measure-slug-strapi-example-slug-tsx" */),
  "component---src-pages-wissensspeicher-strapi-measure-topic-slug-strapi-measure-slug-tsx": () => import("./../../../src/pages/wissensspeicher/{strapiMeasure.topic__slug}/{strapiMeasure.slug}.tsx" /* webpackChunkName: "component---src-pages-wissensspeicher-strapi-measure-topic-slug-strapi-measure-slug-tsx" */),
  "component---src-pages-wissensspeicher-strapi-topic-slug-tsx": () => import("./../../../src/pages/wissensspeicher/{strapiTopic.slug}.tsx" /* webpackChunkName: "component---src-pages-wissensspeicher-strapi-topic-slug-tsx" */)
}

